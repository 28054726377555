import { ApolloClient, gql } from "@apollo/client";
import { useEffect } from "react";
import * as R from "runtypes";

import { hexVersion as currentAppVersion } from "../util/data";
import { useHexFlag } from "../util/useHexFlags.js";

import {
  ProductVersionsDocument,
  useProductVersionsQuery,
} from "./useProductVersionStatus.generated";

gql`
  query ProductVersions {
    productVersions {
      id
      appVersion
      kernelVersion
      sidecarVersion
    }
  }
`;

interface ProductVersionStatus {
  appStale: boolean;
  kernelStale: boolean;
  sidecarStale: boolean;
  clientVersion: string | null;
  serverVersion: string | null;
}

/**
 * Every 5 minutes, request the latest product version from the server.
 *
 * If the server's version has changed and the client's version is determined to be sufficiently stale
 * based on critiera configured in LaunchDarkly, the client will be automatically reloaded.
 * Refresh criteria are controlled via LaunchDarkly here:
 * https://app.launchdarkly.com/projects/default/flags/auto-refresh-client-config
 */
export const useProjectVersionCheck = (client: ApolloClient<object>): void => {
  const pollForProductVersion = useHexFlag("poll-for-product-version");
  const autoRefreshConfig = useHexFlag("auto-refresh-client-config");

  const { clientVersion, serverVersion } = useProductVersionStatus({});

  // Every 5 minutes, check if the app version has changed
  const interval = 60 * 5 * 1000;
  useEffect(() => {
    const id = setInterval(() => {
      if (pollForProductVersion) {
        refreshProductVersionStatus(client);
      }
    }, interval);

    return () => clearInterval(id);
  }, [pollForProductVersion, client, interval]);

  // If the app version has changed and the version is determined to be stale, reload the client.
  useEffect(() => {
    if (
      autoRefreshConfig != null &&
      autoRefreshConfig.enabled &&
      clientVersion != null &&
      serverVersion != null
    ) {
      try {
        const shouldRefresh = evaluateRefreshCriteria({
          clientVersion,
          serverVersion,
          rawFlagConfig: autoRefreshConfig,
        });

        if (shouldRefresh) {
          window.location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [autoRefreshConfig, clientVersion, serverVersion]);
};

/** Retrieve cached product versions */
export const useProductVersionStatus = ({
  currentKernelVersion,
  currentSidecarVersion,
}: {
  currentKernelVersion?: string;
  currentSidecarVersion?: string;
}): ProductVersionStatus => {
  const { data, error, loading } = useProductVersionsQuery();

  if (!data || error || loading) {
    return {
      appStale: false,
      kernelStale: false,
      sidecarStale: false,
      clientVersion: null,
      serverVersion: null,
    };
  }

  return {
    clientVersion: currentAppVersion ?? null,
    serverVersion: data.productVersions.appVersion,
    appStale:
      currentAppVersion != null &&
      currentAppVersion !== data?.productVersions.appVersion,
    kernelStale:
      currentKernelVersion != null &&
      currentKernelVersion !== data?.productVersions.kernelVersion,
    sidecarStale:
      currentSidecarVersion != null &&
      currentSidecarVersion !== data?.productVersions.sidecarVersion,
  };
};

/** Used to trigger a refresh of the latest app versions from the backend */
export const refreshProductVersionStatus = (
  client: ApolloClient<unknown>,
): void => {
  void client.query({
    query: ProductVersionsDocument,
    fetchPolicy: "network-only",
  });
};

const AutoClientRefreshFlagConfig = R.Record({
  badClientVersion: R.Optional(R.Union(R.String, R.Null)),
  enabled: R.Boolean,
  majorVersionDiff: R.Optional(R.Union(R.Number, R.Null)),
  minorVersionDiff: R.Optional(R.Union(R.Number, R.Null)),
  patchVersionDiff: R.Optional(R.Union(R.Number, R.Null)),
});
type AutoClientRefreshFlagConfig = R.Static<typeof AutoClientRefreshFlagConfig>;

/**
 * Utility function to evaluate whether the client should refresh based on the
 * client's version, the server's version, and the config object
 * received from LaunchDarkly.
 *
 * Exported for testing purposes.
 */
export const evaluateRefreshCriteria = ({
  clientVersion,
  rawFlagConfig,
  serverVersion,
}: {
  clientVersion: string;
  serverVersion: string;
  rawFlagConfig: Record<string, unknown>;
}): boolean => {
  // Return early if client and server versions match
  if (clientVersion === serverVersion) {
    return false;
  }

  const configResult = AutoClientRefreshFlagConfig.validate(rawFlagConfig);

  // Return early if the configuration is invalid, or disabled
  if (!configResult.success || configResult.value.enabled !== true) {
    if (!configResult.success) {
      console.error(
        `AutoClientRefreshFlagConfig validation failed: ${configResult.message}`,
      );
    }
    return false;
  }

  const {
    badClientVersion,
    majorVersionDiff,
    minorVersionDiff,
    patchVersionDiff,
  } = configResult.value;

  // Return early if nothing to evaluate
  if (
    badClientVersion == null &&
    majorVersionDiff == null &&
    minorVersionDiff == null &&
    patchVersionDiff == null
  ) {
    return false;
  }

  // Split the version strings into their major, minor, and patch components
  // Assume a version string is in the format "major.minor.patch", ignoring anything
  // after the third period for hotfix versions or non-production versions with commit hashes.
  // ie 3.0.163-rc.1 or 3.0.163-rc.1-29-ga106ffe2f07639f would both be treated as 3.0.163.
  // parseInt(163-rc) will return 163, which is what we want.
  const [clientMajor, clientMinor, clientPatch] = clientVersion.split(".");
  const [serverMajor, serverMinor, serverPatch] = serverVersion.split(".");

  const clientMajorVersionInt = parseInt(clientMajor);
  const serverMajorVersionInt = parseInt(serverMajor);

  // If the client's major version is flagged as old, force a refresh.
  if (
    majorVersionDiff != null &&
    serverMajorVersionInt - clientMajorVersionInt >= majorVersionDiff
  ) {
    return true;
  }

  // If the client's minor version is flagged as old and the
  // client's major version matches the server's major version,
  // force a refresh.
  const clientMinorVersionInt = parseInt(clientMinor);
  const serverMinorVersionInt = parseInt(serverMinor);
  if (
    minorVersionDiff != null &&
    serverMajorVersionInt === clientMajorVersionInt &&
    serverMinorVersionInt - clientMinorVersionInt >= minorVersionDiff
  ) {
    return true;
  }

  // If the client's patch version is flagged as old and the
  // client's major and minor versions match the server's, force a refresh.
  const clientPatchVersionInt = parseInt(clientPatch);
  const serverPatchVersionInt = parseInt(serverPatch);
  if (
    patchVersionDiff != null &&
    serverMajorVersionInt === clientMajorVersionInt &&
    serverMinorVersionInt === clientMinorVersionInt &&
    serverPatchVersionInt - clientPatchVersionInt >= patchVersionDiff
  ) {
    return true;
  }

  // If the client's specific version has been specifically flagged as bad,
  // force a refresh regardless of whether it was considered "old"
  if (badClientVersion != null && clientVersion === badClientVersion) {
    return true;
  }

  return false;
};
